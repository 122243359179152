import React from 'react'
import { StyleSheet, TouchableOpacity, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { white } from 'app/src/constants/colors'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { borderGrayColor } from 'app/src/constants/colors'

type Props = {
  area: number
  goToPage: (navigation) => void
}

export const AreaButton: React.FC<Props> = ({ area, goToPage }) => {
  const navigation = useNavigation()

  return (
    <TouchableOpacity onPress={() => goToPage(navigation)}>
      <Image style={styles.issueImage} source={images[`areaIcon${area}`]} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  issueImage: {
    borderColor: borderGrayColor,
    width: 78,
    height: 78,
    borderRadius: 78 / 2,
    borderWidth: 1,
    backgroundColor: white,
    margin: 5,
  },
})
