import React from 'react'
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { goToAreaDetail } from 'app/src/libs/screen-transition'
import { white } from 'app/src/constants/colors'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { borderGrayColor } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'

type Props = {
  area: number
}

export const Issue: React.FC<Props> = ({ area }) => {
  const navigation = useNavigation()

  const areaName = () => {
    if (area === 1) return '森林保護'
    if (area === 2) return '海洋保護'
    if (area === 3) return '動物保護'
    if (area === 4) return '貧困問題'
    if (area === 5) return '教育'
    if (area === 6) return '医療・災害'
    if (area === 7) return 'クリーンエネルギー'
    if (area === 8) return '研究開発'
  }
  return (
    <TouchableOpacity style={styles.issueItem} onPress={() => goToAreaDetail(navigation, area)}>
      <Image style={styles.issueImage} source={images[`areaIcon${area}`]} />
      <Text style={styles.issueTitle}>{areaName()}</Text>
      <View style={styles.issueDetail}>
        <Image style={styles.btnSmallDetailImage} source={images['btnSmallDetail']} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  issueItem: {
    marginHorizontal: 27,
    marginBottom: 20,
    paddingRight: 20,
    borderColor: borderGrayColor,
    borderRadius: 39,
    borderWidth: 1,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: white,
  },
  issueImage: {
    height: 78,
    width: 78,
    resizeMode: 'contain',
  },
  issueTitle: {
    marginLeft: 12,
  },
  issueDetail: {
    marginLeft: 'auto',
  },
  btnSmallDetailImage: {
    width: 80,
    height: 28,
    resizeMode: 'contain',
  },
})
