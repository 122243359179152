import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

// constants
import { windowWidth } from 'app/src/constants/layout'

//libs
import { images } from 'app/src/libs/images'
import { black } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'

type Props = {
  npoId: number
}

export const NpoItem: React.FC<Props> = ({ npoId, children }) => {
  return (
    <>
      <Image style={styles.npoHeaderImage} source={images[`npoHeaderImage${npoId}`]} />
      <View style={styles.areAction}>
        <View style={styles.areaButtons}>{children}</View>
        <Text type={'small'} align={'right'} style={styles.notes}>
          活動しているエリアを見る↑
        </Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  npoHeaderImage: {
    width: windowWidth,
    height: windowWidth * 1.3,
    resizeMode: 'contain',
  },
  areAction: {
    marginTop: -70,
  },
  areaButtons: {
    flexDirection: 'row',
    marginBottom: 5,
    marginLeft: 'auto',
  },
  notes: {
    color: black,
    marginRight: 36,
  },
})
