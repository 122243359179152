import React, { createContext } from 'react'
import { useDimensions } from '@react-native-community/hooks'
import { useMediaQuery } from 'react-responsive'

// constants
import { isWeb } from 'app/src/constants/platform'

export type LayoutContextType = {
  isMobile: boolean
  isPc: boolean
  width: number
  height: number
  innerWidth: number
}

const initialState = {
  isMobile: false,
  isPc: false,
  width: 0,
  height: 0,
  innerWidth: 0,
}

export const LayoutContext = createContext<LayoutContextType>(initialState)

export const LayoutProvider = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isPc = isWeb && !isMobile
  const dimensionsWindow = useDimensions().window
  const width = dimensionsWindow.width
  const height = dimensionsWindow.height
  const innerWidth = dimensionsWindow.width > 950 ? 950 : dimensionsWindow.width

  return (
    <LayoutContext.Provider
      value={{
        isMobile,
        isPc,
        width,
        height,
        innerWidth,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
