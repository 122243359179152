import { useHistory } from 'react-router-dom'
import { useNavigation as useNativeNavigation } from '@react-navigation/native'

import { isWeb } from 'app/src/constants/platform'

export function useNavigation() {
  if (isWeb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigation = useHistory()
    return navigation
  }

  if (!isWeb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigation = useNativeNavigation()
    return navigation
  }
}
