import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = () => {
  const { pathname, state } = useLocation<any>()

  useEffect(() => {
    if (state === undefined || state.type === 0) {
      window.scrollTo(0, 0)
    }
  }, [pathname, state])

  return null
}
