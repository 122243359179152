import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, TouchableOpacity, Image } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

//libs
import { images } from 'app/src/libs/images'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { TextInput } from 'app/src/components/basics/text-input'

type ContainerProps = {}

type Props = {
  searchText: string
  TopQuestionItems: listItem[]
  streamingItems: listItem[]
  setSearchText: (searchText: string) => void
  renderList: (label: string) => JSX.Element
}

type listItem = {
  label: string
}

export const QaScreen: React.FC<ContainerProps> = () => {
  const [searchText, setSearchText] = useState<string>('')
  const TopQuestionItems = [
    {
      label: 'パスワードを忘れてしまった',
    },
    {
      label: 'アクション開始の通知が届きません',
    },
    {
      label: '税金控除のやりた方を教えてくだい',
    },
  ]

  const streamingItems = [
    {
      label: '推奨環境を教えてください',
    },
    {
      label: '再生中に止まる場合の対応について',
    },
    {
      label: 'コメントを入力できません',
    },
  ]

  const _renderList = label => (
    <TouchableOpacity onPress={() => console.log('onPress')} style={styles.listItemContent}>
      <Text>{label}</Text>
      <MaterialIcons name="keyboard-arrow-right" size={18} style={styles.arrowRight} />
    </TouchableOpacity>
  )

  return (
    <UI
      searchText={searchText}
      setSearchText={setSearchText}
      TopQuestionItems={TopQuestionItems}
      streamingItems={streamingItems}
      renderList={_renderList}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({ searchText, TopQuestionItems, streamingItems, setSearchText, renderList }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <Text type={'xxlarge'} align={'center'}>
            よくある質問
          </Text>
          <View style={styles.comingSoon}>
            <Image style={styles.comingSoonImage} source={images['comingSoon']} />
          </View>
          {/* <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'}>
              よくある質問
            </Text>
            <View style={styles.searchContent}>
              <TextInput
                label={''}
                onChangeText={text => setSearchText(text)}
                value={searchText}
                placeholder={'検索したい内容をお書きください'}
                translucentGrayBackground
              />
            </View>
            <View style={styles.listTitleContent}>
              <Text type={'quickRTitle'} style={styles.sectionTitle}>
                Top Questions <Text style={styles.sectionSubTitle}>よくある質問</Text>
              </Text>
            </View>
            <View style={styles.line} />
            {TopQuestionItems.map((item, index) => (
              <View style={styles.listItem} key={index}>
                {renderList(item.label)}
              </View>
            ))}
            <View style={styles.line} />
            <View style={styles.listTitleContent}>
              <Text type={'quickRTitle'} style={styles.sectionTitle}>
                Streaming <Text style={styles.sectionSubTitle}>視聴方法について</Text>
              </Text>
            </View>
            <View style={styles.line} />
            {streamingItems.map((item, index) => (
              <View style={styles.listItem} key={index}>
                {renderList(item.label)}
              </View>
            ))}
          </View> */}
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginTop: 20,
  },
  searchContent: {
    marginTop: 40,
  },
  listTitleContent: {
    marginLeft: 28,
    width: 320,
  },
  sectionTitle: {
    marginTop: 47,
    marginBottom: 26,
  },
  sectionSubTitle: {
    marginLeft: 18,
    fontSize: 10,
    opacity: 0.4,
  },
  line: {
    height: 6,
    width: '100%',
    backgroundColor: '#F4F4F4',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#F4F4F4',
  },
  listItemContent: {
    height: 65,
    marginLeft: 40,
    marginRight: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowRight: {
    color: '#cccccc',
  },
  comingSoon: {
    marginHorizontal: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  comingSoonImage: {
    width: 255,
    height: 255,
    marginBottom: 160,
    resizeMode: 'contain',
  },
})
