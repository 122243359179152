import React, { useContext } from 'react'
import { StyleSheet, View, ScrollView, Image, ImageBackground } from 'react-native'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowWidth } from 'app/src/constants/layout'
import { black } from 'app/src/constants/colors'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'
import { useNavigation } from 'app/src/hooks/use-navigation'

// libs
import { images } from 'app/src/libs/images'
import { goToAreaDetail } from 'app/src/libs/screen-transition'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { Issue } from 'app/src/components/advanced/home/issue'
import { AreaButton } from 'app/src/components/advanced/area-button'
import { NpoItem } from 'app/src/components/advanced/others/npo-item'

type ContainerProps = {}

type Props = {
  isPc: boolean
  innerWidth: number
  navigation: any
}

export const HowToScreen: React.FC<ContainerProps> = () => {
  const { isPc, innerWidth } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()

  return <UI isPc={isPc} innerWidth={innerWidth} navigation={navigation} />
}

const UI: React.FC<Props & ContainerProps> = ({ isPc, innerWidth, navigation }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={isPc && styles.contentInner}>
            <View style={styles.mainContent}>
              <View style={styles.titleWrapper}>
                <Text type={'xxlarge'} align={'center'}>
                  meguの寄付の仕組み
                </Text>
              </View>
              <View style={styles.subTitleWrapper}>
                <Text type={'medium'} style={styles.bold}>
                  あなたの楽しみたいが誰かを救う
                </Text>
              </View>
              <Text type={'small'} style={styles.content}>
                様々な領域で、様々な問題が複合的に起きている状況で、それを解決する財源が十分でないという状況が続いています。そこに必要な資金が届く仕組みとしてmeguではあなたが「集まる」「楽しむ」「学ぶ」だけで寄付できる仕組みになっています。
              </Text>
              <View style={styles.aboutMeguImageWrapper}>
                <Image style={styles.aboutMeguImage} source={images['aboutMegu']} />
              </View>
              <Text type={'small'} style={styles.content}>
                寄付という多くの人があまり経験していない行為を、新しく行うのは難しいと思いますが、今までの購買行動に自然に含まれていれば寄付という行為と、社会課題について知る切っ掛けになる。そして、それが日常になる。それを実現するのがmeguです。
              </Text>
              <View style={styles.titleWrapper}>
                <Text type={'xxlarge'} align={'center'}>
                  今後のmeguの展開
                </Text>
              </View>
              <Text type={'small'} style={styles.content}>
                これまで寄付する時に二の足を踏んでしまう理由のうちに、どこに寄付をしていいかわからないという声がありました。確かに、どういう社会課題があり、その社会課題に対する解決策はなにで、どのNPOがどういう活動しているのかを調べて寄付の決断をするのはなかなかの労力です。
                {'\n\n'}
                meguでは自分の興味関心あるエリアにどれくらい送りたいかを指定するだけで寄付できるようになります。
              </Text>
            </View>
            <View style={styles.aboutMeguImageWrapper}>
              <Image style={styles.aboutMeguFigureImage} source={images['aboutMeguFigure']} />
            </View>
            <ImageBackground
              style={[styles.section, isPc && { width: innerWidth, height: innerWidth, marginHorizontal: 'auto' }]}
              source={isPc ? '' : images['earthTop']}
              imageStyle={{ resizeMode: 'cover' }}
            >
              <View style={styles.mainContent}>
                <Text type={'small'} style={[styles.content, styles.contentTop]}>
                  8つのエリアごとにmeguが厳選したNPOが加盟していますので、海が好きな方は海を選ぶだけ。どこにどれくらい贈りたいか設定していただければ、完了です。
                </Text>
                <View style={styles.subTitle2Wrapper}>
                  <Text type={'medium'} style={styles.bold}>
                    それぞれのエリアの課題を見る
                  </Text>
                </View>
                <Text type={'small'} style={styles.content}>
                  今、世界では何が起きているのか、そして私達はそれに対して何ができるのか。それを知ることで、誰を支援すべきか、自分にできる行動は何かを考えられるようになる。そういうことを目的として、それぞれのエリアで起きている現状の課題の大枠を記載しています。また、それぞれのエリアでどういう団体が課題解決に取り組んでいるかも合わせて紹介しているので、是非一読ください。
                </Text>
              </View>
            </ImageBackground>
            <View style={styles.issueList}>
              <Issue area={1} />
              <Issue area={2} />
              <Issue area={3} />
              <Issue area={4} />
              <Issue area={5} />
              <Issue area={6} />
              <Issue area={7} />
              <Issue area={8} />
            </View>

            <View style={styles.mainContent}>
              <View style={styles.titleWrapper2}>
                <Text type={'xxlarge'} align={'center'}>
                  提携団体抜粋
                </Text>
              </View>
              <Text type={'small'} style={styles.npoText}>
                以下がいくつかmeguが提携しているNPO団体です。meguで、自分が楽しみ、自分が学ぶだけで、以下のような社会課題に挑戦している団体を応援することができます。
              </Text>
            </View>
            <View style={styles.npoList}>
              <NpoItem npoId={1}>
                <AreaButton area={1} goToPage={() => goToAreaDetail(navigation, 1, 1)} />
                <AreaButton area={2} goToPage={() => goToAreaDetail(navigation, 2, 3)} />
              </NpoItem>
              <NpoItem npoId={4}>
                <AreaButton area={1} goToPage={() => goToAreaDetail(navigation, 1, 2)} />
              </NpoItem>
              <NpoItem npoId={11}>
                <AreaButton area={6} goToPage={() => goToAreaDetail(navigation, 6, 4)} />
              </NpoItem>
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  mainContent: {
    marginHorizontal: 31,
  },
  contentInner: {
    width: 950,
    marginHorizontal: 'auto',
  },
  section: {
    paddingTop: 55,
    marginTop: -180,
    width: windowWidth,
    height: windowWidth * 2.48,
  },
  titleWrapper: {
    marginVertical: 40,
  },
  titleWrapper2: {
    marginTop: 110,
  },
  subTitleWrapper: {
    marginBottom: 14,
  },
  subTitle2Wrapper: {
    marginTop: 80,
    marginBottom: 14,
  },
  content: {
    color: black,
  },
  bold: {
    fontWeight: 'bold',
  },
  contentTop: {
    marginTop: 170,
  },
  aboutMeguImageWrapper: {
    marginVertical: 24,
    alignItems: 'center',
    zIndex: 1,
  },
  aboutMeguImage: {
    width: 355,
    height: 220,
    resizeMode: 'contain',
  },
  aboutMeguFigureImage: {
    width: 303,
    height: 310,
    justifyContent: 'center',
    marginTop: 30,
  },
  issueList: {
    marginTop: -370,
  },
  npoText: {
    color: black,
    marginTop: 26,
  },
  npoList: {
    marginBottom: 300,
  },
})
