import React from 'react'
import { Platform } from 'react-native'
import { Helmet } from 'react-helmet'

/*
 * Web用のヘッダー
 * metaタグ情報
 */
const isWeb = Platform.OS === 'web'
const TITLE = 'megu'
const DESCRIPTION =
  'みんなの楽しむが誰かの為になるサポート・ネットワーク・サービス！meguはアクションに参加して楽しむだけで社会貢献になる全く新しいサービスです！'
const IMAGE_URL = 'https://megu.green/og_image.png'

type Props = {
  title?: string
  description?: string
  imageUrl?: string
}

export const HeadTag: React.FC<Props> = ({ title = TITLE, imageUrl = IMAGE_URL, description = DESCRIPTION }) => {
  // NOTE: ベースのmeta情報はweb/index.htmlに記載されている
  return isWeb ? (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
    </Helmet>
  ) : null
}
