export const primaryColor = '#0470B9'
export const black = '#1E1E1E'
export const lightGray = '#999999'
export const gray = '#666666'
export const darkGray = '#333333'
export const white = '#FFFFFF'
export const blue = '#2CA5DE'
export const headlineTextColor = '#787878'
export const borderGrayColor = '#EEEEEE'
export const borderLightGrayColor = '#DDDDDD'
export const grayBgColor = '#F1F1F1'
export const lightGrayBgColor = '#F9F9F9'
