import React from 'react'
// import './basic.scss'

const Content: React.FC = ({ children }) => (
  <div style={{ minHeight: 'calc(100vh - 8rem)' }}>
    <>{children}</>
  </div>
)

export const BasicLayout: React.FC = props => {
  return (
    <div>
      <Content children={props.children} />
    </div>
  )
}
