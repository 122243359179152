import { useLocation } from 'react-router-dom'
import { useRoute } from '@react-navigation/native'

import { isWeb } from 'app/src/constants/platform'

export function useParams() {
  if (isWeb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation()
    return require('query-string').parse(location.search)
  }

  if (!isWeb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const route = useRoute()
    return route.params
  }
}
